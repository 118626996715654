import React from 'react';
import './VideoModal.css';

const VideoModal = ({ videoUrl, onClose }) => {
  React.useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        onClose();
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [onClose]);

  return (
    <div className="video-modal">
      <div className="video-modal-overlay" onClick={onClose}></div>
      <div className="video-modal-content">
        <button className="video-modal-close" onClick={onClose} aria-label="Close video modal">✖</button>
        <iframe
          src={videoUrl}
          title="YouTube Video"
          allow="autoplay; encrypted-media"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default VideoModal;
