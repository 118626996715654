// hockeyhub/index.js

// Import your main styles and dependencies
import React from "react";
import ReactDOM from "react-dom";
import App from "./App.js"; // Added '.js' extension
import "./index.css";

// Import Firebase Analytics (import without variable to avoid unused warning)
import "./firebase.js"; // Just importing firebase will ensure all initialization runs

// Load the reCAPTCHA script dynamically
const loadRecaptcha = () => {
  const script = document.createElement("script");
  script.src = `https://www.google.com/recaptcha/enterprise.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
  script.async = true;
  document.body.appendChild(script);
};

// Initialize reCAPTCHA
loadRecaptcha();

// Render your main app component
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
