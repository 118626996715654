import React, { useState, useEffect } from 'react';
import './NewsletterCTA.css';

const NewsletterCTA = () => {
  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false);
  const [cooldownActive, setCooldownActive] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const cooldownTimestamp = localStorage.getItem('subscriptionCooldown');
    if (cooldownTimestamp) {
      const timeRemaining = parseInt(cooldownTimestamp) - Date.now();
      if (timeRemaining > 0) {
        setCooldownActive(true);
        setSubscribed(true);
        const timer = setTimeout(() => {
          setCooldownActive(false);
          setSubscribed(false);
          localStorage.removeItem('subscriptionCooldown');
        }, timeRemaining);
        return () => clearTimeout(timer);
      } else {
        localStorage.removeItem('subscriptionCooldown');
      }
    }
  }, []);

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);

    try {
      const response = await fetch('https://connect.mailerlite.com/api/subscribers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_MAILERLITE_API_KEY}`,
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setSubscribed(true);
        setCooldownActive(true);
        const cooldownDuration = 100000; // 100 seconds
        const cooldownExpiration = Date.now() + cooldownDuration;
        localStorage.setItem('subscriptionCooldown', cooldownExpiration);
        const timer = setTimeout(() => {
          setSubscribed(false);
          setCooldownActive(false);
          setEmail('');
          localStorage.removeItem('subscriptionCooldown');
        }, cooldownDuration);
        return () => clearTimeout(timer);
      } else {
        const errorData = await response.json();
        console.error('Newsletter subscription error:', errorData); // For debugging
        if (errorData.message?.includes('already subscribed')) {
          setError('This email is already subscribed!');
        } else {
          setError('Unable to subscribe at this time. Please try again later.');
        }
      }
    } catch (err) {
      console.error('Newsletter submission error:', err); // For debugging
      setError('Unable to connect to subscription service. Please try again later.');
    }
  };

  return (
    <div className="newsletter-cta">
      <div className="cta-title">Stay Updated on the Habs — Subscribe Now!</div>
      <form onSubmit={handleSubmit} className="cta-form">
        <input
          type="email"
          value={email}
          onChange={handleInputChange}
          placeholder="Enter your email..."
          required
          className="email-input"
          disabled={cooldownActive}
        />
        <button
          type="submit"
          className={`cta-button ${subscribed ? 'subscribed' : ''}`}
          disabled={cooldownActive}
        >
          {subscribed ? 'Subscribed! 🚨' : 'Subscribe'}
        </button>
      </form>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default NewsletterCTA;
