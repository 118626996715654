import React from 'react';
import './Banner.css';

const Banner = ({ title, subtitle, link }) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <div className="banner">
        <div className="banner-content">
          <div className="banner-title">{title}</div>
          <div className="banner-subtitle">{subtitle}</div>
        </div>
      </div>
    </a>
  );
};

export default Banner;
