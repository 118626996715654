import React from 'react';
import './App.css';
import HomePage from './Views/HomePage.js';
import './firebase.js';


function App() {
  return (
    <div className="App">
      <HomePage />
    </div>
  );
  
}

export default App;
