import React, { useState } from 'react';
import './NavBar.css';
import hockeyhubLogo from '../assets/images/HockeyHub.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const NavBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="navbar">
      <div className="navbar-top">
        <div className="logo-section">
          <a href="#home" className="logo-link">
            <img
              src={hockeyhubLogo}
              alt="HockeyHub Logo"
              className="logo"
            />
            <div className="slogan">
              <h4>HOCKEY HUB</h4>
            </div>
          </a>
        </div>

        <div className="menu-controls">
          <button onClick={toggleMenu} className="hamburger-menu-btn" aria-label="Toggle menu">
            <FontAwesomeIcon icon={faBars} />
          </button>
        </div>
      </div>

      <nav className={`nav-links ${menuOpen ? 'nav-links-open' : ''}`}>
        <a href="#about">ABOUT</a>
        <a href="#contact">CONTACT</a>
      </nav>
    </header>
  );
};

export default NavBar;
