import React, { useState, useEffect, useCallback, useMemo } from 'react';
import './Feed.css';
import VideoModal from '../components/VideoModal.js';
import hockeyhubLogo from '../assets/images/HockeyHub.png'; // Import the placeholder image
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase.js'; // Ensure this path is correct

const Feed = () => {
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState('');
  const [visibleItems, setVisibleItems] = useState([]);
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;
  const [contentItems, setContentItems] = useState([]);
  const [filter, setFilter] = useState({
    type: [],
    source: [],
    topic: [],
    dateRange: '',
    publisher: ''
  });

  const openVideo = (videoUrl) => {
    const autoplayUrl = `${videoUrl}?autoplay=1`;
    setCurrentVideoUrl(autoplayUrl);
    setIsVideoOpen(true);
  };

  const closeVideo = () => {
    setIsVideoOpen(false);
  };

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "content"));
        const fetchedContent = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
  
        const formattedData = fetchedContent.map(item => ({
          ...item,
          thumbnailUrl: item.thumbnailUrl || null, // Pull the thumbnail from the source or leave as null
        }));
  
        setContentItems(formattedData);
      } catch (error) {
        console.error('Error fetching content from Firestore:', error);
      }
    };
  
    fetchContent();
  }, []);
 

  const handleFilterChange = (filterCategory, value) => {
    setFilter(prevFilter => ({
      ...prevFilter,
      [filterCategory]: prevFilter[filterCategory].includes(value)
        ? prevFilter[filterCategory].filter(item => item !== value)
        : [...prevFilter[filterCategory], value]
    }));
  };

  const handlePublisherChange = (event) => {
    setFilter(prevFilter => ({
      ...prevFilter,
      publisher: event.target.value
    }));
  };

  const filteredItems = useMemo(() => {
    return contentItems.filter(item => {
      const matchesType = filter.type.length === 0 || filter.type.includes(item.type);
      const matchesSource = filter.source.length === 0 || filter.source.includes(item.source);
      const matchesTopic = filter.topic.length === 0 || filter.topic.includes(item.topic);
      const matchesPublisher = !filter.publisher || item.publisher === filter.publisher;

      return matchesType && matchesSource && matchesTopic && matchesPublisher;
    });
  }, [contentItems, filter]);

  const sortedItems = useMemo(() => {
    return [...filteredItems].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  }, [filteredItems]);

  const loadMoreItems = useCallback(() => {
    setVisibleItems((prevItems) => {
      const newItems = sortedItems.slice(0, (page + 1) * itemsPerPage);
      setPage((prevPage) => prevPage + 1);
      return newItems;
    });
  }, [page, sortedItems]);

  useEffect(() => {
    setVisibleItems(sortedItems.slice(0, itemsPerPage));
  }, [sortedItems]);

  return (
    <div className="feed-container">
      {isVideoOpen && <VideoModal videoUrl={currentVideoUrl} onClose={closeVideo} />}

      <div className="feed-content">
        <div className="feed-sidebar">
          <div className="filter-section">
            <h4 className="filter-title">FILTERS</h4>

            <div className="filter-dropdown">
              <label htmlFor="date-range-filter">Date Range</label>
              <select id="date-range-filter" name="date-range">
                <option value="">Select date range</option>
                <option value="last-4-hrs">Last 4 hrs</option>
                <option value="last-24-hrs">Last 24 hrs</option>
                <option value="last-72-hrs">Last 72 hrs</option>
                <option value="last-week">Last Week</option>
                <option value="last-month">Last Month</option>
                <option value="last-year">Last Year</option>
              </select>
            </div>

            <div className="filter-divider"></div>

            <div className="filter-heading">Content</div>
            <div className="filter-option">
              <input type="checkbox" id="article-filter" name="article" onChange={() => handleFilterChange('type', 'article')} />
              <label htmlFor="article-filter">Article</label>
            </div>
            <div className="filter-option">
              <input type="checkbox" id="video-filter" name="video" onChange={() => handleFilterChange('type', 'video')} />
              <label htmlFor="video-filter">Video</label>
            </div>
            <div className="filter-option">
              <input type="checkbox" id="podcast-filter" name="podcast" onChange={() => handleFilterChange('type', 'podcast')} />
              <label htmlFor="podcast-filter">Podcast</label>
            </div>

            <div className="filter-divider"></div>

            <div className="filter-heading">Source</div>
            <div className="filter-option">
              <input type="checkbox" id="official-filter" name="official" onChange={() => handleFilterChange('source', 'Official')} />
              <label htmlFor="official-filter">Official</label>
            </div>
            <div className="filter-option">
              <input type="checkbox" id="media-filter" name="media" onChange={() => handleFilterChange('source', 'Media')} />
              <label htmlFor="media-filter">Media</label>
            </div>
            <div className="filter-option">
              <input type="checkbox" id="fans-filter" name="fans" onChange={() => handleFilterChange('source', 'Fans')} />
              <label htmlFor="fans-filter">Fans</label>
            </div>

            <div className="filter-divider"></div>

            <div className="filter-heading">Topic</div>
            <div className="filter-option">
              <input type="checkbox" id="news-filter" name="news" onChange={() => handleFilterChange('topic', 'News')} />
              <label htmlFor="news-filter">News</label>
            </div>
            <div className="filter-option">
              <input type="checkbox" id="lineups-filter" name="lineups" onChange={() => handleFilterChange('topic', 'Lineups')} />
              <label htmlFor="lineups-filter">Lineups</label>
            </div>
            <div className="filter-option">
              <input type="checkbox" id="trades-filter" name="trades" onChange={() => handleFilterChange('topic', 'Trades')} />
              <label htmlFor="trades-filter">Trades</label>
            </div>
            <div className="filter-option">
              <input type="checkbox" id="signings-filter" name="signings" onChange={() => handleFilterChange('topic', 'Signings')} />
              <label htmlFor="signings-filter">Signings</label>
            </div>
            <div className="filter-option">
              <input type="checkbox" id="rumors-filter" name="rumors" onChange={() => handleFilterChange('topic', 'Rumors')} />
              <label htmlFor="rumors-filter">Rumors</label>
            </div>
            <div className="filter-option">
              <input type="checkbox" id="analysis-filter" name="analysis" onChange={() => handleFilterChange('topic', 'Analysis')} />
              <label htmlFor="analysis-filter">Analysis</label>
            </div>

            <div className="filter-divider"></div>

            <div className="filter-dropdown">
              <label htmlFor="source-filter">Publisher</label>
              <select id="source-filter" name="source" onChange={handlePublisherChange}>
                <option value="">Select source</option>
                <option value="montreal-canadiens">Montreal Canadiens</option>
                <option value="montreal-gazette">Montreal Gazette</option>
                <option value="the-athletic">The Athletic</option>
                <option value="sportsnet">Sportsnet</option>
                <option value="tsn">TSN</option>
                <option value="rds">RDS</option>
                <option value="tva">TVA</option>
                <option value="global-montreal">Global Montreal</option>
                <option value="la-presse">La Presse</option>
                <option value="hockey-inside-out">Hockey Inside/Out</option>
                <option value="32-thoughts">32 Thoughts</option>
                <option value="the-sick-podcast">The Sick Podcast with Tony Mariano</option>
              </select>
            </div>
          </div>
        </div>

        <div className="feed-main">
          {visibleItems.map((item, index) => (
            <div
              key={index}
              className="feed-card"
              onClick={() => item.videoUrl ? openVideo(item.videoUrl) : item.link ? window.open(item.link, '_blank') : null}
              role="link"
              tabIndex={0}
              aria-label={`Read article: ${item.title}`}
            >
              <img 
                src={item.thumbnailUrl ? item.thumbnailUrl : hockeyhubLogo} 
                alt="" 
                className="feed-thumbnail" 
                loading="lazy" 
              />
              <div className="feed-info">
                <div className="feed-title"><strong>{item.title}</strong></div>
                <div className="feed-meta">{item.author || 'Unknown Author'} • {item.createdAt}</div>
              </div>
            </div>
          ))}
          {(visibleItems.length < sortedItems.length) && (
            <button onClick={loadMoreItems} className="load-more-button">
              Load More
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Feed;
