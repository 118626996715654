import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-links">
        <a href="#contact">Contact Us</a>
      </div>
      <div className="social-icons">
        <a href="#facebook" aria-label="Facebook">
          <FontAwesomeIcon icon={faFacebook} />
        </a>
        <a href="#twitter" aria-label="Twitter">
          <FontAwesomeIcon icon={faTwitter} />
        </a>
        <a href="#youtube" aria-label="YouTube">
          <FontAwesomeIcon icon={faYoutube} />
        </a>
      </div>
      <p className="copyright">© Copyright 2024 - Hockey Hub</p>
    </footer>
  );
};

export default Footer;
